import { defineStore } from "pinia";

/* eslint-disable no-console */

export const useCartStore = defineStore("cartStore", () => {
  const cartData = ref();
  const cartMerged = ref(false);
  const { $api } = useNuxtApp();
  const debug = false;

  const createNewCart = async () => {
    if (debug)
      console.log(
        "createNewCart start. Let's clear localStorage: webshop.cart.code",
      );
    localStorage.setItem("webshop.cart.code", "");
    await $api("cart-new")
      .then((newCartCode: string) => {
        if (debug) console.log("newCartData arrived", newCartCode);
        if (newCartCode) {
          localStorage.setItem("webshop.cart.code", newCartCode);
          cartData.value = null;
        } else {
          // eslint-disable-next-line no-lonely-if
          if (debug)
            console.error("Error while trying to create new cart in API");
        }
      })
      .catch((err: any) => {
        if (debug)
          console.error(
            "Error while trying to create new cart in API:",
            err.message,
          );
        const nuxtError = useError();
        nuxtError.value = {
          statusCode: err?.response?.status || 500,
          statusMessage: err?.response?.statusText || "An error occurred",
        };
      });
  };

  const initCart = async () => {
    /*
      fixme:
      - the creation of new cart shouldn't be duplicated here but call another method which throws createError if cart cannot be created
    */
    if (debug) console.log("initCart start");

    // check if we already have a cart
    if (localStorage.getItem("webshop.cart.code")) {
      if (debug) {
        console.log(
          "Found existing cart in localeStore: ",
          localStorage.getItem("webshop.cart.code"),
        );
        console.log("Check if the found existing cart exists in the API too?");
      }

      let needNewCart = false;

      await $api(`cart-exists/${localStorage.getItem("webshop.cart.code")}`)
        .then((cartExists: Record<string, any>) => {
          if (debug) console.log("cartExists", cartExists);
          if (cartExists.exists === true) {
            if (debug) {
              console.log("cart exists on API side too");
              console.log(cartExists);
            }
            cartMerged.value = cartExists.itemsMerged;
          } else {
            // need to create a new cart
            if (debug) console.log("need to create new cart!");
            needNewCart = true;
          }
        })
        .catch((err) => {
          if (debug)
            console.error("Error when checking if cart exists", err.message);
          const nuxtError = useError();
          nuxtError.value = {
            statusCode: err?.response?.status || 500,
            statusMessage: err?.response?.statusText || "An error occurred",
          };
        });

      if (needNewCart) {
        await createNewCart();
      }
    } else {
      if (debug)
        console.log(
          "NO existing cart in localeStore, we try to create new cart ",
        );
      await createNewCart();
    }

    // check if we now have a cart in local
    if (localStorage.getItem("webshop.cart.code") !== "") {
      return true;
    }
    return false;
  };

  const getCartCode = async () => {
    const cartReady = await initCart();

    if (cartReady) {
      return localStorage.getItem("webshop.cart.code");
    }

    return null;
  };

  const addProduct = async (productId: number, quantity: number) => {
    // console.log('adding: ', productId, quantity, localStorage.getItem('webshop.cart.code'));

    const cartReady = await initCart();

    let added = false;

    if (cartReady) {
      await $api("cart-item", {
        method: "POST",
        body: {
          code: localStorage.getItem("webshop.cart.code"),
          product: productId,
          quantity,
        },
      }).then(() => {
        fetchCartData();
        // console.log("item added");
        added = true;
      });
      return added;
    } else {
      return false;
    }
  };

  const fetchCartData = async (withAwait = false) => {
    const cartReady = await initCart();

    if (cartReady) {
      if (debug)
        console.log(
          "fetching cart data: " + localStorage.getItem("webshop.cart.code"),
        );
      if (localStorage.getItem("webshop.cart.code")) {
        if (withAwait) {
          await $api(`cart/${localStorage.getItem("webshop.cart.code")}`)
            .then((cart: any) => {
              if (debug) console.log("cart", cart);
              cartData.value = cart;
            })
            .catch((err) => {
              if (debug)
                console.error("Error when getting cart data", err.message);
              
              localStorage.setItem("webshop.cart.code", "");

              /*
              console.log("error page redirect 1");

              const nuxtError = useError();
              nuxtError.value = {
                statusCode: err?.response?.status || 500,
                statusMessage: err?.response?.statusText || "An error occurred",
              };
              */
            });
        } else {
          $api(`cart/${localStorage.getItem("webshop.cart.code")}`)
            .then((cart: any) => {
              if (debug) console.log("cart", cart);
              cartData.value = cart;
            })
            .catch((err) => {
              if (debug)
                console.error("Error when getting cart data", err.message);

              localStorage.setItem("webshop.cart.code", "");

              /*
              console.log("error page redirect 2");

              const nuxtError = useError();
              nuxtError.value = {
                statusCode: err?.response?.status || 500,
                statusMessage: err?.response?.statusText || "An error occurred",
              };
              */
            });
        }
      }
    }
  };

  const clearCartMerged = () => {
    cartMerged.value = false;
  };

  return {
    // export these during development if you need to debug them in vue devtools
    // -----------

    // real exports
    addProduct,
    cartData,
    cartMerged,
    clearCartMerged,
    fetchCartData,
    getCartCode,
  } as const;
});
